import React from "react";

import PropTypes from "prop-types";

import "./Button.css";

const Button = (props) => (
  <button disabled={props.disabled} className="dashboard__button" onClick={props.onClick}>
    {props.image ? <img src={props.image} alt="button" /> : null}
  </button>
)

Button.propTypes = {
  image: PropTypes.string,
};
export default Button;