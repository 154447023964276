import React from 'react';

import './App.css';
import getData from './mock';

import Dashboard from './components/Dashboard';

function App() {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    setData(getData(100));
  }, []);
  return (
    <div className="App">
      <Dashboard data={data} />
    </div>
  );
}

export default App;
