import React from "react";

import PropTypes from "prop-types";

import "./DataTable.css";

const DataTable = (props) => {
  return (
    <div className="dashboard-data-table">
      <h2>{props.children || props.title}</h2>
      <div>
        <table>
          <tbody>
            {
              props.data.map((x, i) => (
                <tr key={x[props.dataKey]}>
                  {
                    Object.keys(x).map(k => (
                      <td key={k}>
                        {x[k]}
                      </td>
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

DataTable.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  /**
   * Use to identify the unique field per row
   */
  dataKey: PropTypes.string.isRequired,
  data: PropTypes.array,
}

DataTable.defaultProps = {
  data: [],
}

export default DataTable;