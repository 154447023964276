import React from "react";

import PropTypes from "prop-types";

import Chart from "../Chart";
import Controls from "../Controls";
import DataTable from "../DataTable";

import "./Dashboard.css";

const Dashboard = (props) => {
  const { data } = props;
  const [selectedFields, setSelectedFields] = React.useState(['pv', 'uv']);
  const [index, setIndex] = React.useState(50);
  const [history, setHistory] = React.useState([]);
  const [popedHistory, setPopedHistory] = React.useState([]);
  const handlePrev = () => {
    if (!history.length) return;
    const prevIndex = history.pop();
    setPopedHistory([...popedHistory, index]);
    setIndex(prevIndex);
    setHistory([...history]);
  }
  const handleForward = () => {
    if (!popedHistory.length) return;
    const nextIndex = popedHistory.pop();
    setHistory([...history, index]);
    setIndex(nextIndex);
    setPopedHistory([...popedHistory]);
  }
  const handleSelectIndex = (newIndex) => {
    history.push(index);
    if (history.length > 10) {
      history.shift();
    }
    setHistory([...history]);
    setIndex(newIndex);
  }
  const fields = [
    { key: 'uv', label: "Records/Second", value: 200, color: "#00738b" },
    { key: 'pv', label: "Records/Transaction", value: 200, color: "#f08e21" },
    { key: 'amt', label: "Transactions/Second", value: 200, color: "#922543" },
  ]
  const trail = data.length ? data[index].trail : [];
  const longestTransaction = data.length ? data[index].longestTransaction : [];
  const scn = data.length ? data[index].scnData : [];
  return (
    <div className="dashboard">
      <div className="dashboard__row">
        <section>
          <div>
            <Controls canPrev={history.length > 0} canForward={popedHistory.length > 0} onClickBack={handlePrev} onClickForward={handleForward} fields={fields} selectedFields={selectedFields} onChangeSelectedFields={setSelectedFields} />
            <Chart selected={data[index]} data={data} onSelectIndex={handleSelectIndex} fields={fields.filter(x => selectedFields.includes(x.key))} />
          </div>
        </section>
      </div>
      <div className="dashboard__row">
        <div>
          <section>
            <DataTable data={trail} dataKey="key" title="Trail File Summary" />
          </section>
          <section>
            <DataTable data={longestTransaction} dataKey="key" title="Longest Transaction" />
          </section>
        </div>
        <section>
          <DataTable data={scn} dataKey="key">
            SCN <a href="#10613193">10613193</a>
          </DataTable>
        </section>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  data: PropTypes.array,
}

export default Dashboard;