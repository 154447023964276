import React from "react";

import PropTypes from "prop-types";

import Button from "../Button";
import CheckBox from "../CheckBox";

import style from "./Controls.module.scss";
import imgLeft from "./left.png";
import imgRight from "./right.png";
import imgSearch from "./search.png";

const Controls = (props) => {
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const { onChangeSelectedFields, fields, selectedFields } = props;
  const handleChangeSelectedFields = (e) => {
    const key = e.target.name;
    if (e.target.checked && !selectedFields.includes(key)) {
      onChangeSelectedFields([...selectedFields, key]);
    } else if (!e.target.checked) {
      onChangeSelectedFields(selectedFields.filter(x => x !== key));
    }
  }
  const handleClickFullscreen = () => {
    if(isFullScreen){
      document.exitFullscreen();
    }else{
      document.body.requestFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  }
  return (
    <div className={style.controls}>
      <div className={style.checkboxes}>
        {
          fields.map(x => (
            <CheckBox
              key={x.key}
              checked={selectedFields.includes(x.key)}
              name={x.key}
              color={x.color}
              label={x.label}
              value={x.value}
              onChange={handleChangeSelectedFields} />
          ))
        }
      </div>
      <div className={style.buttons}>
        <Button disabled={!props.canPrev} image={imgLeft} onClick={props.onClickBack} />
        <Button disabled={!props.canForward} image={imgRight} onClick={props.onClickForward} />
        <Button image={imgSearch} onClick={handleClickFullscreen} />
      </div>
    </div>
  );
}

Controls.propTypes = {
  canPrev: PropTypes.bool,
  canForward: PropTypes.bool,
  fields: PropTypes.array,
  selectedFields: PropTypes.array,
  onChangeSelectedFields: PropTypes.func,
  onClickBack: PropTypes.func,
  onClickForward: PropTypes.func,
}

Controls.defaultProps = {
  fields: [],
  selectedFields: [],
}

export default Controls;