import React from "react";

import PropTypes from "prop-types";

import "./CheckBox.css";

const CheckBox = (props) => (
  <label className="dashboard__check-box">
    <i style={{backgroundColor: props.color}} />
    <input name={props.name} onChange={props.onChange} checked={props.checked} type="checkbox" />
    <span>{props.label}</span>
    <strong>{props.value}</strong>
  </label>
);

CheckBox.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckBox;