
function getRandomInt(min, max) {
  return Math.min(min, Math.floor(Math.random() * Math.floor(max)));
}

function plusOrMinus() {
  return Math.random() <= 0.5 ? -1 : 1
}

export default function getData(total = 100) {
  const mockData = [];

  for (let i = 1; i <= total; i += 1) {
    const date = new Date('2018-06-26 00:00:00');
    date.setDate(date.getDate() + i);
    const prev = mockData[i - 2];
    const scn = Math.max(0, (prev ? prev.scn : 10000000) + (plusOrMinus() * getRandomInt(100000, 190000)));
    mockData.push({
      name: date.toLocaleDateString(),
      date,
      uv: Math.max(0, (prev ? prev.uv : getRandomInt(10, 1000)) + (plusOrMinus() * getRandomInt(10, 20))),
      pv: Math.max(0, (prev ? prev.pv : getRandomInt(100, 1000)) + (plusOrMinus() * getRandomInt(20, 30))),
      amt: Math.max(0, (prev ? prev.amt : getRandomInt(300, 1000)) + (plusOrMinus() * getRandomInt(50, 60))),
      scn,
      scnData: [
        { key: getRandomInt(100001, 190001) },
        { key: getRandomInt(100002, 190001) },
        { key: getRandomInt(100003, 190001) },
        { key: getRandomInt(100004, 190001) },
      ],
      trail: [
        { key: "Database", value: "connection.string.params.com" },
        { key: "Trail FIle", value: `path/${getRandomInt(100000, 190000)}` },
        { key: "#Processed Records", value: getRandomInt(90000, 190000) },
        { key: "#Transactions", value: getRandomInt(60000, 190000) },
        { key: "Inherits", value: getRandomInt(100000, 190000) },
        { key: "Updates", value: getRandomInt(100000, 190000) },
        { key: "Deletes", value: getRandomInt(0, 10) },
        { key: "Metadata", value: "0" },
      ],
      longestTransaction: [
        { key: "#Records", value: getRandomInt(1, 30) },
        { key: "RBA", value: getRandomInt(100, 2000) },
        { key: "SCN", value: scn },
      ]
    });
  }
  return [...mockData];
}