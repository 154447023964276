import React from "react";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

import PropTypes from "prop-types";

import "./Chart.css";

const CustomizedAxisTick = (props) => {
  const {
    x, y, stroke, payload,
  } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fontSize="0.8em" textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text>
    </g>
  );
}

const CustomizedToolTip = (props) => {
  return (
    <text>
        SCN
    </text>
  );
}

const Chart = ({ data, fields, onSelectIndex, selected }) => {
  const handleOnClick = (args) => {
    onSelectIndex(args.activeTooltipIndex);
  }
  return (
    <div className="dashboard-chart">
      <ResponsiveContainer>
        <LineChart data={data} onClick={handleOnClick} margin={{ bottom: 40 }}>
          <CartesianGrid vertical={false} horizontal={true} />
          <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
          <YAxis />
          {selected ? <ReferenceLine x={selected.name} stroke="#DDD" label={`SCN ${selected.scn}`} /> : null}
          <Tooltip isAnimationActive={false} />
          {fields.map(x => (
            <Line key={x.key} dot={false} dataKey={x.key} stroke={x.color} isAnimationActive={false} />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      date: PropTypes.object,
      uv: PropTypes.number,
      pv: PropTypes.number,
      amt: PropTypes.number,
      scn: PropTypes.number,
    })
  ).isRequired,
  selected: PropTypes.object,
  onSelectIndex: PropTypes.func,
  fields: PropTypes.array,
}

Chart.defaultProps = {
  data: [],
  fields: [],
  onSelectIndex: () => { }
}
export default Chart;